import React, { useEffect, useState } from "react"
import NavBar from "./NavBar";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getAuth } from "firebase/auth";
import { doc, getDoc, getFirestore } from "firebase/firestore";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button } from '@mui/material';
import LinkComponent from "./LinkComponent";


function LeadMagnetPage() {
    const { quizName } = useParams(); // Access the quiz name from the route
    const location = useLocation();
    const { index } = location.state || {}; 

    const auth = getAuth();
    const navigate = useNavigate();
    const [quiz, setQuiz] = useState(null);
    const[loading, setLoading] = useState(true)

    useEffect(()=>{
      async function getUserData() {
        //console.log("HAHA")

        if (auth.currentUser !== null)
        {
            const firestore = getFirestore()
            const docRef = doc(firestore, "users", auth.currentUser.uid)
            const docSnap = await getDoc(docRef)
          
            const data = docSnap.exists() ? docSnap.data() : null
          
            if (data === null || data === undefined) return null
            setQuiz([...data.quizzes].reverse()[index]);
            setLoading(false)

        }
      }
      getUserData();

    })

    if (loading) {
      return <div>Loading...</div>;
    }

    const answers = quiz.answers;

    // Extract unique questions for the table headers
    const questions = answers ? (answers[0]?.answers.map((a) => a.question) || []): [];

    const exportAsCSV = () => {
      // Add headers (Email and questions)
      const headers = ["Email", ...questions];
      
      // Add data rows
      const rows = answers.reverse().map((lead) => {
        const row = [lead.email]; // Start with the email
        questions.forEach((question) => {
          const answerObj = lead.answers.find((a) => a.question === question);
          row.push(answerObj ? answerObj.answer : "N/A");
        });
        return row;
      });
  
      // Combine headers and rows
      const csvContent = [headers, ...rows]
        .map((row) => row.join(",")) // Convert each row array to a comma-separated string
        .join("\n"); // Join all rows with newlines
  
      // Trigger download
      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "leads.csv");
      link.style.display = "none";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    };

  return (
    <div className="homeStructure">
    <NavBar/>
    <div className="pageContent">
    <div className="dataRow">
        <div className="dataBox">
            <p style={{fontSize:'45px', marginBottom:'-10px', fontWeight:'bold', color:'#4361ee'}}>{answers.length}</p>
            <p>Leads Collected</p>
        </div>
        <LinkComponent link={quiz.link} />

    </div>
{answers.length !== 0 && 
<div>
  <Button
        variant="contained"
        color="primary"
        onClick={exportAsCSV}
        style={{ marginBottom: "20px" }}
      >
       Download CSV
       </Button>
    <TableContainer component={Paper}>
        <Table aria-label="lead answers table">
          <TableHead>
            <TableRow>
              <TableCell>Email</TableCell>
              {questions.map((question, index) => (
                <TableCell key={index}>{question}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {[...answers].reverse().map((lead, index) => (
              <TableRow key={index}>
                <TableCell component="th" scope="row">
                  {lead.email}
                </TableCell>
                {questions.map((question, qIndex) => {
                  // Find the answer for the current question
                  const answerObj = lead.answers.find((a) => a.question === question);
                  return (
                    <TableCell key={qIndex}>
                      {answerObj ? answerObj.answer : 'N/A'}
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      </div>
    }
    {
        answers.length === 0 &&
        <p>The collected leads will be displayed here.</p>
    }
      </div>
    </div>
  )
};

export default LeadMagnetPage;
